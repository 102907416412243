import React, { useEffect } from 'react';
import Aetna from 'src/components/pages/aetna/Aetna';
import { locale, LOCALE_ES, toggleLocale } from 'src/utils';

export default function AetnaEs() {
  useEffect(() => {
    if (locale === LOCALE_ES) return;
    toggleLocale();
  }, [locale]);

  return <Aetna />;
}
